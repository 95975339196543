<template>
	<div
		class="full-height pa-20"
	>
		<h6>{{ program.name }}</h6>
		<div class="pa-10 bg-white width-90" >

			<table class="table   td-left">
				<col width="120px">
				<tbody>
				<tr>
					<th>카테고리</th>
					<td>
						<select
							v-model="item.QCategoryIdx"
							class="pa-10 "
						>
							<option value="">카테고리를 선택하세요</option>
							<option
								v-for="(category, index) in items_category"
								:key="'category_' + index"
								:value="category.idx"
							>{{ category.name }}</option>
						</select>
					</td>
				</tr>
				<tr>
					<th>제목</th>
					<td><input v-model="item.title" class="input-box pa-10" placeholder="제목을 입력하세요" maxlength="50"/></td>
				</tr>
				<tr>
					<th>내용</th>
					<td>
						<editor
							v-model="item.content"
							height="350px"
							initialEditType="markdown"
							ref="content"
							class="text-left "
						/>
					</td>
				</tr>
				</tbody>
			</table>

			<div
				class="mt-30 justify-center"
			>
				<button
					class="bg-identify pa-10-20 mr-10"
					@click="save"
				>저장</button>
				<button
					class="bg-ddd pa-10-20"
					@click="toList"
				>이전</button>
			</div>
		</div>
	</div>
</template>

<script>

import '@toast-ui/editor/dist/toastui-editor.css';
import { Editor } from '@toast-ui/vue-editor';

export default {
	name: 'SampleList',
	components: { Editor },
	data: function(){
		return {
			program: {
				name: 'FAQ 등록'
				,top: true
				,title: true
				,bottom: false
			}
			,search: {
				page: this.$route.query.page ? this.$route.query.page : 1
				,size: this.$route.query.size ? this.$route.query.size : 10
				,search_type: this.$route.query.search_type ? this.$route.query.search_type : ''
				,search_value: this.$route.query.search_value ? this.$route.query.search_value : ''
			}
			,search_option: {

			}
			,item: {
				QCategoryIdx: ''
			}
			,items_category: [

			]
		}
	}
	,computed: {
		item_list: function(){
			return this.items.filter(function(item){
				return item
			})
		}
	}
	,methods: {
		getData: async function(){
			try{
				this.$layout.onLoading()
				const result = await this.$Axios({
					method: 'get'
					,url: 'faq/category'
					,data: this.search
				})

				if(result.success){
					this.items_category = result.data
				}else{
					this.$layout.setNotify( { type: 'success', message: result.message})
				}
			}catch(e){
				this.$layout.setNotify( { type: 'error', message: this.$language.common.error})
				console.log(e)
			}finally {
				this.$layout.offLoading()
			}
		}
		,save: async function(){
			this.item.content = this.$refs.content.invoke("getMarkdown")
			try{
				this.$layout.onLoading()
				const result = await this.$Axios({
					method: 'post'
					,url: 'faq'
					,data: this.item
				})
				if(result.success){
					this.$layout.setNotify( { type: 'success', message: result.message})
					this.toList()
				}else{
					this.$layout.setNotify( { type: 'error', message: result.message})
				}
			}catch(e){
				console.log(e)
			}finally {
				this.$layout.offLoading()
			}
		}
		,toList: function(){
			this.$router.back()
		}

	}
	,created() {
        this.$layout = this.$root.$children[0].$children[0]
        this.$layout.onLoad(this.program)
		this.getData()
	}
}
</script>